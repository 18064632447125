<template>
  <div>
    <router-view>

    </router-view>
  </div>
</template>

<script>
export default {
    name: "Settings"
}
</script>

<style scoped lang="scss">
  .title {
    font-size: 26px;
    font-weight: 500;
    color: #263238;
    margin-bottom: 0;
    padding-left: 10px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 1.6;
    color: #71797e;
    padding-left: 10px;

  }

  .form {
    padding: 10px;
    margin-top: 20px;
  }
</style>
